
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        // Clear all session storage items
        sessionStorage.clear();

        // Redirect to the home or login page
        navigate('/');
    }, [navigate]);

    return null; // This component does not render anything
}
