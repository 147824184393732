
import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "./Config";
import BackgroundImg from "../Images/schooledesign.png";
// import BackgroundImg from "./rhone.svg";
// import Stock1 from "./Images/img-1.jpg";
import { Popover, TimePicker } from "antd";
import Swal from "sweetalert2";
import { Link, json } from "react-router-dom";
import TotalFeeImg from "../Images/totalfee.jpeg";
import BirlaLogo from "../Images/birla-logo.png";
import RequestSVG from "../Images/requests.svg";
import PaidAmountImg from "../Images/paidamount.jpeg";
import '../CSS/popup.css';
import { Collapse } from 'antd';
import VisitSvg from '../Images/visit.svg';
import PickupSvg from '../Images/pickup.svg';

export default function Dashboard() {
  
  const [studentsData, setStudentsData] = useState([]);
  const [userMobileNo, setUserMobileNo] = useState([]);
  const [storedUserData, setStoredUserData] = useState([]);
  const [data, setData] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [recentData, setRecentData] = useState([]);
  const [PendingDataCount, setPendingDataCount] = useState([]);
  const [studentFeesData, setStudentFeesData] = useState([]);
  const [paidAmount, setPaidAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [pendingAmount, setPendingAmount] = useState(0);
  // const [searchInput, setSearchInput] = useState("");
  // const [storedData, setStoredData] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [announceloading, setAnnounceLoading] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showAnnouncePopup, setShowAnnouncePopup] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [selectedItems, setSelectedItems] = useState([]);
  // const [selectedStudent, setSelectedStudent] = useState(null);
  const [editData, setEditData] = useState([]);
  // const itemsPerPage = 8;

  const [pickupChecked, setPickupChecked] = useState(false);
  const [visitChecked, setVisitChecked] = useState(false);
  const [othersChecked, setOthersChecked] = useState(false);
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [announcments, setAnnouncments] = useState([]);
  const [notificationsData, setNotificationsData] = useState([]);
  const [advertisementImages, setAdvertisementImages] = useState([]);
  const [selectedAnnounceData, setSelectedAnnounceData] = useState("");

  const [selectedStudentRegId, setSelectedStudentRegId] = useState('');
  const [selectedStudentTrnId, setSelectedStudentTrnId] = useState('');
  const [selectedStudentStandardId, setSelectedStudentStandardId] = useState('');
  const [selectedStudentGender, setSelectedStudentGender] = useState("");

  useEffect(() => {
    
    const storedUserMobileNo = sessionStorage.getItem("userDetails");
    const storedUserData = sessionStorage.getItem("userDataFromSession");
    if (storedUserMobileNo && storedUserData) {
      const parsedMobileNoData = JSON.parse(storedUserMobileNo);
      const parsedUserData = JSON.parse(storedUserData);
      setUserMobileNo(parsedMobileNoData);
      setStoredUserData(parsedUserData[0]);
    }
  }, []);

  useEffect(() => {
    const storedStudentsData = sessionStorage.getItem('studentsData');
    if (storedStudentsData) {
      setStudentsData(JSON.parse(storedStudentsData));
    } else if (storedUserData.MobileNo) {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${API_BASE_URL}login/mobileNoCheck?MobileNo=${storedUserData.MobileNo}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const jsonData = await response.json();

          sessionStorage.setItem('studentsData', JSON.stringify(jsonData.ResultData));
          setStudentsData(jsonData.ResultData);
        } catch (error) {
          setError(error.message);
        } finally {
          setError('Final message.');
        }
      };

      fetchData();
    }
  }, [storedUserData]);

  // useEffect(() => {
  //   if (studentsData.length > 0) {
  //       setSelectedStudent(studentsData[0].StudentTrnId);
  //   }
  // }, [studentsData]);

  useEffect(() => {

    const savedStudentRegId = sessionStorage.getItem('selectedStudentRegId');
    const savedStudentTrnId = sessionStorage.getItem('selectedStudentTrnId');
    const savedStudentStandId = sessionStorage.getItem('selectedStudentStandId');
    const savedStudentGendder = sessionStorage.getItem('selectedStudentGender');
    const savedStudentCheck = sessionStorage.getItem('selectedStudentCheck');

    if (!savedStudentCheck && (studentsData[0]?.RegID || studentsData[0]?.StudentTrnId || studentsData[0]?.StandardId || studentsData[0]?.Gender)) {
      setSelectedStudentRegId(studentsData[0]?.RegId);
      setSelectedStudentTrnId(studentsData[0]?.StudentTrnId);
      setSelectedStudentStandardId(studentsData[0]?.StandardId);
      setSelectedStudentGender(studentsData[0]?.Gender);
      sessionStorage.setItem('selectedStudentRegId', studentsData[0]?.RegId);
      sessionStorage.setItem('selectedStudentTrnId', studentsData[0]?.StudentTrnId);
      sessionStorage.setItem('selectedStudentStandId', studentsData[0]?.StandardId);
      sessionStorage.setItem('selectedStudentGender', studentsData[0]?.Gender);
    }

    else {
      // If there is a previously selected student in sessionStorage, use that selected student
      setSelectedStudentRegId(savedStudentRegId);
      setSelectedStudentTrnId(savedStudentTrnId);
      setSelectedStudentStandardId(savedStudentStandId);
      setSelectedStudentGender(savedStudentGendder);
    }

  }, [studentsData]);

  const handleSelectRegIdChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const value = selectedOption.value;
    const trnId = selectedOption.getAttribute('data-trnid');
    const standId = selectedOption.getAttribute('data-standid');
    const gender = selectedOption.getAttribute('data-gender');

    setSelectedStudentRegId(value);
    setSelectedStudentTrnId(trnId);
    setSelectedStudentStandardId(standId);

    sessionStorage.setItem('selectedStudentRegId', value);
    sessionStorage.setItem('selectedStudentTrnId', trnId);
    sessionStorage.setItem('selectedStudentStandId', standId);
    sessionStorage.setItem('selectedStudentGender', gender);
    sessionStorage.setItem('selectedStudentCheck', true);

  };
  
  useEffect(() => {
    if (storedUserData.MobileNo) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await fetch(
            `${API_BASE_URL}visit/GetVisitorsRequests?MobileNo=${storedUserData.MobileNo}&SuperId=${storedUserData.SuperId}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const jsonData = await response.json();
  
          setData(jsonData.ResultData);
          sessionStorage.setItem('requestsData', JSON.stringify(jsonData.ResultData));
          setLoading(true);
  
        } catch (error) {
          setLoading(false);
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }
  
  }, [storedUserData]);

  useEffect(() => {
    if (data && data && data.length > 0) {
      const sortedData = [...data].sort((a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn));
      const recentFive = sortedData.slice(0, 6);
      const statusOne = data.filter(item => item.Status === 1);
      setRecentData(recentFive);
      setPendingData(statusOne);
    }
    const pendingPercentage = data.length > 0 ? (pendingData.length / data.length) * 100 : 0;
    setPendingDataCount(pendingPercentage);

  }, [data && data.length > 0]);

  useEffect(() => {
    const totalPaidAmount = studentFeesData.reduce((total, item) => total + item.PaidAmount, 0);
    const totalAmount = studentFeesData.reduce((total, item) => total + item.Amount, 0);

    const totalPenidngAmount = totalAmount - totalPaidAmount;

    setPaidAmount(totalPaidAmount);
    setTotalAmount(totalAmount);
    setPendingAmount(totalPenidngAmount);
  }, [studentFeesData]);
  
  useEffect(() => {
    const fetchData = async () => {
      if (selectedStudentTrnId) {
        setLoading(true);
        try {
          if (selectedStudentTrnId) {
            const response = await fetch(`${API_BASE_URL}student/GetStudentFees?SttId=${selectedStudentTrnId}`);

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const jsonData = await response.json();

            setStudentFeesData(jsonData.ResultData);
          }
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoading(false);
        }
      }
    };

    fetchData();
  }, [selectedStudentTrnId]);


  useEffect(() => {

    if (storedUserData.SuperId) {
      const fetchData = async () => {
        setAnnounceLoading(true);
        try {
          const response = await fetch(
            `${API_BASE_URL}comm/GetLatestNotiAdvAnno?SuperId=${storedUserData.SuperId}&Type=5`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const jsonData = await response.json();
          setAnnouncments(jsonData.ResultData);
          setAnnounceLoading(false);
        } catch (error) {
          setError(error.message);
          setAnnounceLoading(false);
        } finally {
          setAnnounceLoading(false);
        }
      };

      fetchData();
    }

  }, [storedUserData]);

  // const handleSearchInputChange = (e) => {
  //   setSearchInput(e.target.value);
  // };

  // const filteredData = data.filter((item) => {
  //   const searchValue = searchInput.toLowerCase(); // Convert search value to lowercase
  //   return (
  //     // Check if any of the properties contain the search value
  //     (item.Reason && item.Reason.toLowerCase().includes(searchValue)) ||
  //     (item.VisitorName &&
  //       item.VisitorName.toLowerCase().includes(searchValue)) ||
  //     // Filter by RequestType
  //     (item.RequestType === 1 && searchValue === "visit") ||
  //     (item.RequestType === 2 && searchValue === "pickup")
  //   );
  // });

  const handleEditButtonClick = (item) => {
    
    setEditData({
      RequestID: item.RequestID,
      RegID: item.RegID || [], // Ensure it's an array
      StartDateTime: formatDateForInput(item.StartDateTime),
      EndDateTime: formatDateForInput(item.EndDateTime),
      VisitorName: item.VisitorName,
      Reason: item.Reason,
      RequestType: item.RequestType,
      Status: item.Status,
      mobileno: storedUserData.MobileNo,
      GuardianDetailsid: item.GuardianDetailsid,
      StudentName: item.studentname,
    });
    setShowEditPopup(true);
  };  
  
  const handleAnnounceClick = (item) => {
    setSelectedAnnounceData(item);
    setShowAnnouncePopup(true);
  };

  const handleAnnounceClosePopup = () => {
    setShowAnnouncePopup(false);
  };

  const handleEditClosePopup = () => {
    setShowEditPopup(false);
  };

//   const handleChangeStudent = (event) => {
//     const selectedRegID = event.target.value;
//     setEditData((prevData) => ({
//       ...prevData,
//       RegID: selectedRegID, // Update the RegID with the selected value
//     }));
// };


  // const filteredOptions = studentsData.map((student) => ({
  //     stuID: student.RegId,
  //     name: student.StudentName,
  //   }))
  //   .filter((item) => !selectedItems.includes(item));

  const handleSave = (e) => {

    e.preventDefault();
    setSubmitBtnLoading(true);

    const editedData = {
      requestId: editData.RequestID,
      RegID: editData.RegID,
      StartDateTime: formatDateForServer(editData.StartDateTime),
      EndDateTime: formatDateForServer(editData.EndDateTime),
      VisitorName: editData.VisitorName,
      Reason: editData.Reason,
      RequestType: editData.RequestType,
      Status: editData.Status,
      mobileno: editData.mobileno,
      GuardianDetailsid: editData.GuardianDetailsid,
      SuperId: storedUserData.SuperId
    };
  
    fetch(`${API_BASE_URL}visit/UpdateRequest`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(editedData)
    })
    .then((response) => response.json())
      .then((data) => {
        if (data.Status) {
          setSubmitBtnLoading(false);
          // Show success message
          Swal.fire({
            title: "Success",
            text: data.Message,
            icon: "success",
          }).then((result) => {
            // Reload the page after the user acknowledges the success message
            if (result.isConfirmed || result.isDismissed) {
              window.location.reload();
            }
          });
        } else {
          setSubmitBtnLoading(false);
          // Show error message
          Swal.fire({
            title: "Error",
            text: data.Message,
            icon: "error",
          });
        }
      })
      .catch((error) => {
        setSubmitBtnLoading(false);
        console.error("Error:", error);
        // Handle errors
      }); // Handle response data
  };

  const handleCheckboxChange = (checkbox) => {
    if (checkbox === "pickup") {
      setPickupChecked(true);
      setVisitChecked(false);
      setOthersChecked(false);
      setFormData((prevState) => ({ ...prevState, RequestType: 2 }));
    } else if (checkbox === "visit") {
      setPickupChecked(false);
      setVisitChecked(true);
      setOthersChecked(false);
      setFormData((prevState) => ({ ...prevState, RequestType: 1 }));
    } else {
      setPickupChecked(false);
      setVisitChecked(false);
      setOthersChecked(true);
      setFormData((prevState) => ({ ...prevState, RequestType: 2 }));
    }
  };

  const [formData, setFormData] = useState({
    RegID: [],
    StartDateTime: "",
    EndDateTime: "",
    VisitorName: "",
    Reason: "",
    RequestType: 0, // Default value, to be updated based on checkbox selection
    mobileNo: storedUserData.MobileNo,
    SuperId: storedUserData.SuperId,
    GuardianDetailsid: 0, // Assuming this value is static
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [startDateTime, setStartDateTime] = useState('');
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState('');

  const handleStartTimeChange = (value) => {
    const newTime = value ? value.format('HH:mm:ss') : '';
    setSelectedStartTime(newTime);
    if (formData.StartDateTime) {
      const combinedDateTime = combineDateTime(formData.StartDateTime, newTime);
      setStartDateTime(combinedDateTime);
    }
  };

  const handleEndTimeChange = (value) => {
    const newTime = value ? value.format('HH:mm:ss') : '';
    setSelectedEndTime(newTime);
    if (formData.EndDateTime) {
      const combinedDateTime = combineDateTime(formData.EndDateTime, newTime);
      setEndDateTime(combinedDateTime);
    }
  };
  const combineDateTime = (date, time) => {
    return `${date} ${time}`;
  };


  const handleSubmit = (event) => {

    event.preventDefault();
    setSubmitBtnLoading(true);

    // Access storedData.Mobile directly from the formData
    formData.mobileNo = storedUserData.MobileNo;
    formData.RegID = [selectedStudentRegId];
    // const startDate = new Date(formData.StartDateTime);
    // const year = startDate.getFullYear();
    // const month = String(startDate.getMonth() + 1).padStart(2, "0");
    // const day = String(startDate.getDate()).padStart(2, "0");
    // const hours = String(startDate.getHours()).padStart(2, "0");
    // const minutes = String(startDate.getMinutes()).padStart(2, "0");
    // const seconds = String(startDate.getSeconds()).padStart(2, "0");
    
    // formData.StartDateTime = `${formData.StartDateTime} ${selectedStartTime}`;
    formData.StartDateTime = startDateTime;
    formData.EndDateTime = endDateTime;
    formData.mobileNo = userMobileNo.mobile;
    formData.SuperId = storedUserData.SuperId;

    if (formData.RequestType == 1) {
      formData.EndDateTime = formData.StartDateTime
    }

    fetch(`${API_BASE_URL}visit/CreateVisitorPickup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Status) {
          setSubmitBtnLoading(false);
          // Show success message
          Swal.fire({
            title: "Success",
            text: data.Message,
            icon: "success",
          }).then((result) => {
            // Reload the page after the user acknowledges the success message
            if (result.isConfirmed || result.isDismissed) {
              window.location.reload();
            }
          });
        } else {
          setSubmitBtnLoading(false);
          // Show error message
          Swal.fire({
            title: "Error",
            text: data.Message,
            icon: "error",
          });
        }
      })
      .catch((error) => {
        setSubmitBtnLoading(false);
        console.error("Error:", error);
        // Handle errors
      }); // Handle response data
  };

  const getStatusColor = (status) => {
    switch (status) {
        case 1:
            return 'badge-light-warning';
        case 2:
            return 'badge-light-success';
        case 3:
            return 'badge-light-danger';
        case 4:
            return 'badge-light-info';
        case 5:
            return 'badge-light-primary';
        default:
            return 'badge-light-light';
    }
  };

  const getStatusInfo = (statusId) => {
    switch (statusId) {
      case 1:
        return { name: "Pending" };
      case 2:
        return { name: "Success" };
      case 3:
        return { name: "Rejected" };
      case 4:
        return { name: "Picked" };
      case 5:
        return { name: "Droped" };
      default:
        return { name: "Unknown" }; // Default to black color for unknown statuses
    }
  };

  const removeAmPm = (timeString) => {
    return timeString.replace(/\s[AP]M$/, ""); // Replace "AM" or "PM" followed by a space at the end of the string with an empty string
  };

  const removeAmPmAndTime = (dateTimeString) => {
    return dateTimeString.replace(/\s[AP]M$/, "").split(" ")[0]; // Remove "AM" or "PM" suffix and then split by space to get only date
  };

  // Function to generate a random color from the array
  
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';

    const [datePart, timePart] = dateString.split(' ');
    if (!datePart || !timePart) return '';

    const [day, month, year] = datePart.split('-');
    const [time, period] = timePart.split(' ');

    if (!day || !month || !year || !time || !period) return '';

    // Convert 12-hour time to 24-hour time if needed
    let [hours, minutes] = time.split(':');
    if (!hours || !minutes) return '';

    if (period.toLowerCase() === 'pm' && hours !== '12') {
        hours = parseInt(hours) + 12;
    } else if (period.toLowerCase() === 'am' && hours === '12') {
        hours = '00';
    }

    // Ensure single-digit day and month are formatted correctly
    const formattedDay = day.padStart(2, '0');
    const formattedMonth = month.padStart(2, '0');
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.padStart(2, '0');

    // Return the formatted string
    return `${year}-${formattedMonth}-${formattedDay}T${formattedHours}:${formattedMinutes}`;
};

  const formatDateForServer = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };


  const content = (
    <div
      className=" menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-245px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src="assets/media/avatars/300-3.jpg" />
          </div>
          <div className="d-flex flex-column">
            <div className="fw-bold d-flex align-items-center fs-5">
              {studentsData[0]?.Father}
              <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
                Guardian
              </span>
            </div>
            <a
              href="#"
              className="fw-semibold text-muted text-hover-primary fs-7"
            >
              {studentsData[0]?.MobileNo}
            </a>
          </div>
        </div>
      </div>
      <div className="separator my-2"></div>
      <div className="menu-item px-5">
        <Link to="/student-info">
          <a className="menu-link px-5"><i className="fa-solid fa-graduation-cap text-primary me-2"></i>Student Info</a>
        </Link>
      </div>
      <div className="menu-item px-5">
        <Link to="/change-password">
          <a className="menu-link px-5"><i className="fa-solid fa-key text-danger me-2"></i>Change Password</a>
        </Link>
      </div>

      <div className="menu-item px-5">
        <Link to="/logout">
          <a
            className="menu-link px-5 text-danger"
          >
            Sign Out <i className="fa-solid fa-arrow-right-from-bracket ms-2 text-danger mt-1"></i>
          </a>
        </Link>
      </div>
    </div>
  );

  useEffect(() => {
    if (storedUserData.SuperId) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await fetch(
            `${API_BASE_URL}comm/GetLatestNotiAdvAnno?SuperId=${storedUserData.SuperId}&Type=6&StandId=${selectedStudentStandardId}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const jsonData = await response.json();
          console.log(jsonData, 'nnnnnnnnnnnnnnn', selectedStudentStandardId)
          setNotificationsData(jsonData.ResultData);
          sessionStorage.setItem('notificationsData', JSON.stringify(jsonData.ResultData));
          setLoading(true);
  
        } catch (error) {
          setLoading(true);
          setError(error.message);
          
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }
  
  }, [storedUserData, selectedStudentStandardId]);

  const items = notificationsData && notificationsData.map((item, index) => ({
    key: index.toString(),
    label: item.Title,
    children: <p>{item.Description}</p>,
  }));

  const contentNoti = (
    <div
      className=" menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 "
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          Notifications <i className="fa-regular fa-comments ms-2 fs-3 text-info"></i>
        </div>
      </div>
      <div className="separator my-2"></div>
     
      <div className="menu-item px-1">
        <a className="menu-link ">
          {notificationsData && notificationsData.length > 0 ? (
            <>
              {/* <span className="bullet bg-primary me-3"></span> */}
              <Collapse  ghost items={items} />
            </>
          ) : (
            'No notifications available.'
          )}
        </a>
      </div>
    </div>
  );

  // const divStyle = {
  //   backgroundColor: "#1B283F",
  //   backgroundPosition: "0 calc(100% + 0.5rem)",
  //   backgroundSize: "100% auto",
  //   backgroundImage: `url(${BackgroundImg})`,
  //   height: "16rem",
  // };

  useEffect(() => {
    if (storedUserData.SuperId) {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${API_BASE_URL}comm/GetLatestNotiAdvAnno?SuperId=${storedUserData.SuperId}&Type=4`
          );
          
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          
          const jsonData = await response.json();
          const imageNames = jsonData.ResultData;
  
          
          if (imageNames.length > 0) {
            const images = imageNames.map(imagename => ({
              url: `https://pcuploadfiles.azurewebsites.net/download?path=${imagename.Filepath}`
            }));
            setAdvertisementImages(images);
          }
        } catch (error) {
          setError(error.message);
        } finally {
          console.log("Fetch operation completed");
        }
      };
  
      fetchData();
    }
    }, [storedUserData]);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    
    useEffect(() => {
      if (advertisementImages.length > 0) {
        const intervalId = setInterval(() => {
          setCurrentImageIndex(prevIndex => {
            const nextIndex = (prevIndex + 1) % advertisementImages.length;
            return nextIndex;
          });
        }, 5000);
        
        return () => clearInterval(intervalId);
      }
    }, [advertisementImages]);

    const divStyle = {
      backgroundColor: "#1B283F",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundImage: `url('${advertisementImages[currentImageIndex]?.url ? advertisementImages[currentImageIndex]?.url : BackgroundImg}')`,
      height: "16rem",
    };

  // const divStyle = {
  //   backgroundColor: "#1B283F",
  //   backgroundPosition: "center",
  //   backgroundSize: "cover",
  //   backgroundImage: `url('https://pcuploadfiles.azurewebsites.net/download?path=campus/20019/advert/image2.jpeg')`,
  //   height: "16rem",
  // };
  

  const divStyle1 = {
    backgroundColor: "#4AB58E",
    backgroundPosition: "calc(100% + 1rem)",
    backgroundSize: "25% auto",
    // backgroundImage: `url(${Custom1})`,
  };

  const formatAmount = (amount) => {
    if (!amount) return '';
    return amount.toLocaleString('en-IN'); // Format according to Indian numbering system
  };


  return (
    <div className="d-flex flex-column flex-root">
      <div className="page d-flex flex-row flex-column-fluid">
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <div
            id="kt_header"
            className="header fixed-top"
            style={{ position: 'sticky', top: 0, zIndex: 1030 }}
            data-kt-sticky="true"
            data-kt-sticky-name="header"
            data-kt-sticky-offset="{default: '200px', lg: '300px'}"
          >
            <div className="container-xxl d-flex flex-grow-1 flex-stack">
              <div className="d-flex align-items-center me-5">
                <Link to='/dashboard'>
                  <a>
                    <img
                      alt="Logo"
                      src={BirlaLogo}
                      className="h-70px h-lg-80px me-2 me-lg-9"
                    />
                  </a>
                </Link>
              </div>
              <div
                className="topbar d-flex align-items-stretch flex-shrink-0"
                id="kt_topbar"
              >
                <div className="d-flex align-items-center ms-2 ms-lg-4">
                  <div
                    className="btn btn-icon btn-custom w-30px h-30px w-lg-40px h-lg-40px btn-color-success position-relative me-2"
                    id="kt_drawer_chat_toggle"
                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                  >
                    <i className="fa-solid fa-code-pull-request fs-2" ></i>
                  </div>
                  <Popover placement="bottomRight" content={contentNoti}>
                    <div
                      className="btn btn-icon btn-custom w-30px h-30px w-lg-40px h-lg-40px btn-color-success position-relative"
                    >
                      <i className="fa-regular fa-bell fs-2"></i>
                      <span className="bullet bullet-dot bg-danger h-6px w-6px position-absolute translate-middle ms-7 mb-3 animation-blink"></span>
                    </div>
                  </Popover>
                 
                </div>
                <div
                  className="d-flex align-items-center ms-2 ms-lg-4"
                  id="kt_header_user_menu_toggle"
                >
                  <div
                    className="cursor-pointer symbol symbol-30px symbol-lg-40px"
                    data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                  >
                    <Popover placement="bottomRight" content={content}>
                      <img
                        className="symbol symbol-30px symbol-lg-40px"
                        src="assets/media/avatars/300-3.jpg"
                        alt="user"
                      />
                    </Popover>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
            <div
                id="kt_toolbar_container"
                className="container-xxl d-flex flex-stack flex-wrap"
            >
                <div className="page-title d-flex flex-column w-100">
                    <div className="breadcrumb-container d-flex justify-content-between align-items-center">
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 mb-1">
                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                                <Link to='/dashboard'>
                                    <span className="text-gray-600 text-hover-primary">
                                        <i className="ki-duotone ki-home text-gray-700 fs-3"></i>
                                    </span>
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <i className="ki-duotone ki-right fs-7 text-gray-700 mx-n1"></i>
                            </li>
                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1 fs-3">Dashboard</li>
                        </ul>
                        <select 
                          className="form-select" 
                          style={{ width: '16rem' }} 
                          value={selectedStudentRegId}
                          onChange={handleSelectRegIdChange}
                        >
                          {studentsData && studentsData.map((item) => (
                              <option 
                                key={item.RegId}
                                value={item.RegId} 
                                data-trnid={item.StudentTrnId} 
                                data-standid={item.StandardId} 
                                data-gender={item.Gender}
                              >
                                  {item.StudentName}
                              </option>
                          ))}
                        </select>
                    </div>
                    <span className="page-desc text-muted fs-7 fw-semibold pt-1"></span>
                </div>
            </div>
        </div>

          <div
            id="kt_content_container"
            className="d-flex flex-column-fluid align-items-start container-xxl"
          >
            <div className="content flex-row-fluid" id="kt_content">
              <div className="row g-5 g-lg-10">
                <div className="col-xl-4 mb-xl-10">
                  <div
                    className="card bgi-no-repeat h-xl-100 mb-5 mb-lg-10"
                    style={divStyle}
                  >
                    <div className="card-body"></div>
                  </div>
                </div>

                <div className="col-xl-4">
                  <div className="row g-5 g-lg-10">
                    <a
                      href="#"
                      className="card bgi-no-repeat h-200px mb-xl-7"
                      style={divStyle1}
                    >
                      <div className="card-body d-flex flex-column align-items-start justify-content-center">
                        <h2 className="text-white fw-bold mb-3">
                          Announcements
                          <i className="fa-solid fa-bullhorn text-white ms-3 fs-3"></i>
                        </h2>
                        
                        {announceloading ? (
                          <h5 className="text-white text-center">
                            Loading...
                            {/* <span className="spinner-border spinner-border-sm text-warning ms-1"></span> */}
                          </h5>
                        ) : announcments && announcments.length === 0 ? (
                          <p className="text-center">No data available</p>
                        ) : (
                          announcments && announcments.map((item, index) => {
                            
                              return (
                                <div className="d-flex align-items-center justify-content-between w-100 mb-2" key={index} >
                                  <p className="text-white mb-0 d-flex align-items-center" onClick={() => handleAnnounceClick(item)}>
                                    <i className="fa-solid fa-circle text-white fs-10 mt-1 me-2"></i>
                                    {item.Title}
                                  </p>
                                  <i className="fa-solid fa-arrow-right text-white" onClick={() => handleAnnounceClick(item)}></i>
                                </div>
                              );
                            })
                          )}
                      </div>
                    </a>
                    <div className="col-6 mb-lg-10">
                      <a href="#" className="card bg-danger h-150px">
                        <div className="card-body d-flex flex-column justify-content-between">
                          {/* <i className="ki-duotone ki-element-11 text-white fs-2hx ms-n1 flex-grow-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                            <span className="path4"></span>
                          </i> */}
                          <img
                            src={TotalFeeImg}
                            style={{ width: "3.2rem", marginTop: "-1rem" }}
                            alt="TotalFee"
                          />
                          <div className="d-flex flex-column">
                            <div className="text-white fw-bold fs-1 mb-0 mt-1">
                              <i className="fa-solid fa-indian-rupee-sign text-white fs-3 me-1"></i>
                              {totalAmount && formatAmount(totalAmount)}
                            </div>
                            <div className="text-white fw-semibold fs-6">
                              Total Fee
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-6 mb-5 mb-lg-10">
                      <a href="#" className="card bg-light-info h-150px">
                        <div className="card-body d-flex flex-column py-6 px-6">
                          <div className="d-flex flex-column flex-grow-1 mb-5">
                            <i className="fa-solid fa-hourglass-half text-dark fs-1 mb-4"></i>
                            {/* <span className="text-gray-500 fw-semibold me-2 fs-7">
                              Pending Requests
                            </span> */}
                            <div className="text-dark fw-semibold fs-6">
                              Pending Requests
                            </div>
                            <span className="fw-bold fs-1 text-gray-900">
                              {pendingData && pendingData.length > 0 ? pendingData.length : 0}
                            </span>
                          </div>
                          <div className="progress h-7px bg-warning bg-opacity-25">
                          <div
                            className="progress-bar bg-warning"
                            role="progressbar"
                            style={{ width: `${PendingDataCount}%` }}
                            aria-valuenow={PendingDataCount}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="row g-5 g-lg-10">
                    <div className="col-6 mb-lg-10">
                      <div
                        className="card h-150px bgi-no-repeat bgi-size-cover bgi-position-y-center h-150px"
                        style={{ backgroundImage: `url(${PaidAmountImg})` }}
                      >
                        <div className="card-body p-6">
                          <a
                            href="#"
                            className="text-black text-hover-primary fw-bold fs-2"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_app"
                          >
                            Paid Amount
                          </a>
                          <div className="text-dark fw-bold fs-1 mb-0">
                            <i className="fa-solid fa-indian-rupee-sign text-dark fs-3 me-1"></i>
                            {paidAmount > 0 ? formatAmount(paidAmount) : 0}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 mb-5 mb-lg-10">
                      <a href="#" className="card bg-info h-150px">
                        <div className="card-body d-flex flex-column justify-content-between">
                          <i className="ki-duotone ki-chart-pie-simple text-white fs-2hx ms-n1 flex-grow-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          <div className="d-flex flex-column">
                            <div className="text-white fw-bold fs-1 mb-0 mt-5">
                              {formatAmount(pendingAmount)}
                            </div>
                            <div className="text-white fw-semibold fs-6">
                              Due Amount
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 mb-xl-10">
                  <div className="card bgi-no-repeat h-xl-100 mb-5 mb-lg-1">
                    <div className="card-body">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">
                          Apply Request
                        </span>
                        <img src={RequestSVG} className="ms-2" style={{ width: '1.4rem' }} />
                      </h3>
                      <form onSubmit={handleSubmit}>
                        <div className="min-w-100px mb-3">
                          <label className="required form-label">
                            Student Name
                          </label>
                          <select
                            className="form-select form-select-soid form-select-sm"
                            style={{ cursor: "not-allowed" }}
                            value={selectedStudentRegId}
                            disabled
                          >
                            {studentsData && studentsData.map((item) => (
                              <option key={item.RegId} value={item.RegId}>
                                {item.StudentName}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="mb-3 fv-row">
                          <label className="required form-label mb-3">
                            Visitor Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="VisitorName"
                            value={formData.VisitorName}
                            onChange={handleInputChange}
                            placeholder="Enter visitor name"
                            required
                          />
                        </div>

                        <div className="mb-3 fv-row">
                          <label className="required form-label mb-3">
                            Type
                          </label>
                          <div className="d-flex mb-2">
                            <div className="form-group me-16">
                              <input
                                type="checkbox"
                                id="pickupCheckbox"
                                style={{ cursor: "pointer" }}
                                checked={pickupChecked}
                                onChange={() => handleCheckboxChange("pickup")}
                              />
                              <label
                                htmlFor="pickupCheckbox"
                                style={{ cursor: "pointer" }}
                                className="form-label ms-1"
                              >
                                Outing
                              </label>
                            </div>
                            <div
                              className="form-group me-16"
                              style={{ cursor: "pointer" }}
                            >
                              <input
                                type="checkbox"
                                id="visitCheckbox"
                                style={{ cursor: "pointer" }}
                                checked={visitChecked}
                                onChange={() => handleCheckboxChange("visit")}
                              />
                              <label
                                htmlFor="visitCheckbox"
                                style={{ cursor: "pointer" }}
                                className="form-label ms-1"
                              >
                                Visiting
                              </label>
                            </div>
                            
                          </div>
                        </div>

                        {visitChecked && (
                          <>
                            <div className="mb-3 col-12 d-flex justify-content-between">
                              <div className="d-flex flex-column">
                                <label className="required form-label mb-3">
                                  Visiting Date
                                </label>
                                <input
                                  type="date"
                                  className="form-control form-control-md"
                                  name="StartDateTime"
                                  value={formData.StartDateTime}
                                  onChange={handleInputChange}                                
                                  style={{ height: "2.5rem", minWidth: '13rem' }}
                                  placeholder="Enter visitor name"
                                />
                              </div>
                              <div className="d-flex flex-column">
                                <label className="required form-label mb-3">
                                  {" "}
                                  Time
                                </label>
                                <TimePicker
                                  minuteStep={15}
                                  secondStep={60}
                                  hourStep={1}
                                  onChange={handleStartTimeChange}
                                  // showSecond={false}
                                  // use12Hours={true}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {pickupChecked && (
                          <>
                          <div className="mb-3 col-12 d-flex justify-content-between">
                              <div className="d-flex flex-column">
                                <label className="required form-label mb-3">
                                  Outing Date
                                </label>
                                <input
                                  type="date"
                                  className="form-control form-control-md"
                                  name="StartDateTime"
                                  value={formData.StartDateTime}
                                  onChange={handleInputChange}
                                  style={{ height: "2.5rem", minWidth: '13rem' }}
                                />
                              </div>
                              <div className="d-flex flex-column">
                                <label className="required form-label mb-3">
                                  {" "}
                                  Time
                                </label>
                                <TimePicker
                                  minuteStep={15}
                                  secondStep={60}
                                  hourStep={1}
                                  onChange={handleStartTimeChange}
                                />
                              </div>
                            </div>
                            
                            <div className="mb-3 col-12 d-flex justify-content-between">
                              <div className="d-flex flex-column">
                                <label className="required form-label mb-3">
                                  Drop Date
                                </label>
                                <input
                                  type="date"
                                  className="form-control form-control-md"
                                  name="EndDateTime"
                                  value={formData.EndDateTime}
                                  onChange={handleInputChange}
                                  style={{ height: "2.5rem", minWidth: '13rem' }}
                                  placeholder="Enter visitor name"
                                />
                              </div>
                              <div className="d-flex flex-column">
                                <label className="required form-label mb-3">
                                  {" "}
                                  Time
                                </label>
                                <TimePicker
                                  minuteStep={15}
                                  secondStep={60}
                                  hourStep={1}
                                  onChange={handleEndTimeChange}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <div className="mb-3 fv-row">
                          <label className="required form-label mb-3">
                            Reason
                          </label>
                          <textarea
                            className="form-control form-control-lg"
                            name="Reason"
                            value={formData.Reason}
                            onChange={handleInputChange}
                            placeholder="Enter reason.."
                            rows={1}
                            required
                          ></textarea>
                        </div>

                        <div>
                          <button className="btn btn-lg btn-primary d-flex m-auto">
                            {submitBtnLoading ? "Submitting..." : "Submit"}
                            <i className="ki-duotone ki-arrow-right fs-3 ms-1 mt-1 me-0">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div className="col-xl-12 d-md-block d-lg-block d-sm-none d-xs-none d-none">
                  <div className="card card-flush h-md-100">
                    <div className="card-header pt-7">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-800">
                          Pending Requests
                        </span>
                        <span className="text-gray-500 mt-1 fw-semibold fs-6">
                          Updated 37 minutes ago
                        </span>
                      </h3>
                      <div className="card-toolbar">
                        <a
                          className="btn btn-sm btn-light"
                          data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                        >
                          View All
                          <i className="ki-duotone ki-black-right fs-2 ms-1 text-gray-500"></i>
                        </a>
                      </div>
                    </div>
                    <div className="card-body pt-6">
                      <div className="table-responsive">
                        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                          <thead>
                            <tr className="fs-6 fw-bold text-gray-600 border-bottom-0">
                              <th className="p-0 pb-3 min-w-17px text-start">
                                S.No
                              </th>
                              <th className="p-1 pb-3 min-w-60px text-start">
                                Stu Name
                              </th>
                              <th className="p-0 pb-3 min-w-90px text-start">
                                Apply Dt
                              </th>
                              <th className="p-0 pb-3 min-w-25px text-start">
                                Type
                              </th>
                              <th className="p-0 pb-3 min-w-100px text-start">
                                Visitor
                              </th>
                              <th className="p-0 pb-3 min-w-80px text-start">
                                Pickup
                              </th>
                              <th className="p-0 pb-3 min-w-80px text-start">
                                Drop
                              </th>
                              <th className="p-0 pb-3 min-w-30px text-start">
                                Status
                              </th>
                              <th className="p-0 pb-3 min-w-100px text-start">
                                Reason
                              </th>
                              <th className="p-0 pb-3 min-w-10px text-start">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                          {loading ? (
                            <tr className="text-center">
                              <td colSpan="12" className="text-center">
                                Loading
                                <span className="spinner-border spinner-border-sm text-dark ms-1"></span>
                              </td>
                            </tr>
                            ) :  pendingData && pendingData.length === 0 ? (
                              <tr>
                                <td colSpan="12" className="text-center">
                                  No data available
                                </td>
                              </tr>
                            ) : (
                              pendingData && pendingData.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td className="text-start pe-0">
                                    <span className="text-start fw-bold fs-6">
                                      {item.studentname}
                                    </span>
                                  </td>
                                  <td className="text-start pe-0">
                                    {removeAmPmAndTime(item.CreatedOn)}
                                  </td>
                                  <td className="text-start pe-0">{item.RequestType === 2 ? <img src={PickupSvg} alt="pickup" style={{ height: '4rem' }} /> : <img src={VisitSvg} alt="visit" />}</td>
                                  <td className="text-start pe-0">{item.VisitorName}</td>
                                  <td className="text-start pe-0">
                                    {removeAmPm(item.StartDateTime)}
                                  </td>
                                  <td className="text-start pe-0">
                                    {removeAmPm(item.EndDateTime)}
                                  </td>
                                  <td className="text-start">
                                    <span className={`badge badge-light-warning fw-bold me-auto py-3 `}>
                                      {getStatusInfo(item.Status).name}
                                    </span>
                                  </td>
                                  <td>{item.Reason}</td>
                                  <td>
                                    <i
                                      className="fa-solid fa-file-pen ms-3 fs-3 text-primary"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleEditButtonClick(item)}
                                      title="Edit"
                                    ></i>
                                  </td>
                                </tr>
                             ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <h3 className="card-title align-items-start flex-column d-md-none d-lg-none d-sm-block d-xs-block">
                  <div className="d-flex justify-content-between w-100">
                    <span className="card-label fw-bold text-gray-800">
                      Pending Requests
                    </span>
                    <span className="text-gray-500 ms-auto fw-semibold fs-6">
                      <a
                        className="btn btn-sm btn-light"
                        data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                      >
                        View All
                        <i className="ki-duotone ki-black-right fs-2 ms-1 text-gray-500"></i>
                      </a>
                    </span>
                  </div>
                </h3>

                {loading ? (
                  <h6 className=" text-center d-md-none d-lg-none d-sm-block d-xs-block">
                    Loading
                    <span className="spinner-border spinner-border-sm text-dark ms-1"></span>
                  </h6>
                  ) : pendingData && pendingData.length === 0 ? (
                    <tr>
                      <td colSpan="12" className="text-center">
                        No data available
                      </td>
                    </tr>
                  ) : (
                    pendingData && pendingData.map((item, index) => (
                  <div className="col-md-6 col-xl-4 d-md-none d-lg-none d-sm-block d-xs-block" key={index}>
                    <div className="card border-hover-primary">
                      <div className="card-header border-0 pt-9">
                        <div className="card-title m-0">
                          <div className="symbol">
                            <label className="form-label">Applied on</label>
                            <h2>{removeAmPmAndTime(item.CreatedOn)}</h2>
                          </div>
                        </div>

                        <div className="card-toolbar">
                          <span className={`badge fw-bold me-auto py-3 ${getStatusColor(item.Status)}`}>
                            {getStatusInfo(item.Status).name}
                          </span>
                          <i
                            className="fa-solid fa-file-pen ms-3 fs-3 text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEditButtonClick(item)}
                            title="Edit"
                          ></i>
                        </div>
                      </div>

                      <div className="card-body p-9">
                        <table className="table">
                          <tbody>
                            <tr>
                              <td>Student </td>
                              <th className="fw-semibold">{item.studentname}</th>
                            </tr>
                            <tr>
                              <td>Visitor </td>
                              <th className="fw-semibold">{item.VisitorName}</th>
                            </tr>
                            <tr>
                              <td>Reason</td>
                              <th className="fw-semibold">{item.Reason}</th>
                            </tr>
                          </tbody>
                        </table>

                        <div className="d-flex flex-wrap mb-5">
                          <div className="border border-gray-300 border-dashed border-primary rounded min-w-125px py-3 px-4 me-7 mb-3">
                            <div className="fs-6 text-gray-800 fw-bold">
                              {removeAmPm(item.StartDateTime)}
                            </div>
                            <div className="fw-semibold text-gray-500">
                              Pickup Date
                            </div>
                          </div>

                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
                            <div className="fs-6 text-gray-800 fw-bold">
                              {removeAmPm(item.EndDateTime)}
                            </div>
                            <div className="fw-semibold text-gray-500">
                              Drop Date
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 ))
                )}
              </div>
            </div>
          </div>

          <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
            <div className="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
              <div className="text-gray-900 order-2 order-md-1">
                <span className="text-muted fw-semibold me-1">2024&copy;</span>
                <a
                  href="https://keenthemes.com"
                  target="_blank"
                  className="text-gray-800 text-hover-primary"
                >
                  Perennialcode
                </a>
              </div>
              {/* <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                <li className="menu-item">
                  <a
                    href="https://keenthemes.com"
                    target="_blank"
                    className="menu-link px-2"
                  >
                    About
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href="https://devs.keenthemes.com"
                    target="_blank"
                    className="menu-link px-2"
                  >
                    Support
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href="https://1.envato.market/EA4JP"
                    target="_blank"
                    className="menu-link px-2"
                  >
                    Purchase
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
          .offcanvas.custom-offcanvas {
            width: 400px !important; /* Adjust this as needed */
          }

          @media (max-width: 576px) {
            .offcanvas.custom-offcanvas {
              width: 70% !important; /* Adjust the percentage as needed */
            }
          }
        `}
      </style>

      <div
        className="offcanvas offcanvas-end custom-offcanvas"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">Recent Requests</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="">
            {loading ? (
              <h6 className=" text-center">
                Loading
                <span className="spinner-border spinner-border-sm text-dark ms-1"></span>
              </h6>
              ) : recentData && recentData.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center">
                    No data available
                  </td>
                </tr>
              ) : (
                recentData && recentData.map((item, index) => (
                  <div className="card border-hover-primary mb-3" key={index}>
                    <div className="card-header border-0 pt-9">
                      <div className="card-title m-0">
                        <div className="symbol">
                          <label className="form-label">Applied on</label>
                          <h2>{removeAmPmAndTime(item.CreatedOn)}</h2>
                        </div>
                      </div>

                      <div className="card-toolbar">
                        <span className={`badge ${getStatusColor(item.Status)} fw-bold me-auto py-3`}>
                          {getStatusInfo(item.Status).name}
                        </span>
                      </div>
                    </div>

                    <div className="card-body p-9">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>Visitor </td>
                            <th className="fw-semibold">{item.VisitorName}</th>
                          </tr>
                          <tr>
                            <td>Reason</td>
                            <th className="fw-semibold">{item.Reason}</th>
                          </tr>
                        </tbody>
                      </table>

                      <div className="d-flex flex- mb-5">
                        <div className="border border-gray-300 border-dashed rounded min-w-85px py-3 me-3 mb-3">
                          <div className="fs-6 text-gray-800 fw-bold">
                            {removeAmPm(item.StartDateTime)}
                          </div>
                          <div className="fw-semibold text-gray-500">
                            Pickup Date
                          </div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-85px py-3 mb-3">
                          <div className="fs-6 text-gray-800 fw-bold">
                            {removeAmPm(item.EndDateTime)}
                          </div>
                          <div className="fw-semibold text-gray-500">
                            Drop Date
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      </div>

      {showEditPopup && (
        <div className="popup ">
          <div className="popup-content card">
            <div className="d-flex">
              <h3 className="text-start">Edit Request</h3>
              <p
                style={{ cursor: "pointer" }}
                onClick={handleEditClosePopup}
                className="text-dark d-flex ms-auto"
              >
                <i className="fa-solid fa-xmark"></i>
              </p>
            </div>
            <hr></hr>
            <form onSubmit={handleSave}>
              <div className="d-flex flex-column col-12">
                <div className="d-flex mt-4">
                  <div className="col-6 me-1">
                    <label className="form-label">Student:</label>
                    {/* <select
                      className="form-select"
                      value={editData.RegID}
                      style={{ width: "100%", cursor: 'not-allowed' }}
                      disabled
                    >
                      {studentsData.map((item) => (
                        <option key={item.RegId} value={item.RegId}>
                          {item.StudentName}
                        </option>
                      ))}
                    </select> */}
                    <input 
                      className="form-control"
                      value={editData.StudentName}
                      style={{ cursor: 'not-allowed' }}
                      readOnly
                    />

                  </div>
                  <div className="col-6 ms-1">
                    <label className="form-label">Visitor Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Visitor name"
                      name="VisitorName"
                      required
                      value={editData.VisitorName}
                      onChange={(e) => setEditData({ ...editData, VisitorName: e.target.value })}
                    />
                  </div>
                </div>
                <div className="d-flex mt-2">
                  <div className="col-6 me-1">
                    <label className="form-label">Start Date:</label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      name="StartDateTime"
                      required
                      value={editData.StartDateTime}
                      onChange={(e) => setEditData({ ...editData, StartDateTime: e.target.value })}
                    />
                  </div>
                  <div className="col-6 ms-1">
                    <label className="form-label">End Date:</label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      name="EndDateTime"
                      required
                      value={editData.EndDateTime}
                      onChange={(e) => setEditData({ ...editData, EndDateTime: e.target.value })}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <label className="form-label">Reason</label>
                  <textarea
                    placeholder="Enter Reason"
                    className="form-control"
                    name="Reason"
                    required
                    value={editData.Reason}
                    onChange={(e) => setEditData({ ...editData, Reason: e.target.value })}
                  ></textarea>
                </div>
                <div className="d-flex ms-auto mt-4">
                  <button
                    type="button"
                    className="btn btn-secondary me-2"
                    onClick={handleEditClosePopup}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {submitBtnLoading ? 'Submitting...' : 'Save'}
                  </button>
                </div>
              </div>
            </form>


          </div>
        </div>
      )}

      {showAnnouncePopup && (
        <div className="popup">
          <div className="popup-content card shadow">
            <div className="d-flex justify-content-between align-items-center p-3 bg-info text-light">
              <h6 className="m-0 text-white">{selectedAnnounceData.Title}</h6>
              <button
                className="btn-close btn-close-white border border-white"
                onClick={handleAnnounceClosePopup}
              ></button>
            </div>
            <div className="p-3">
              <h6 className="fw-bold">Description:</h6>
              <p className="m-0">{selectedAnnounceData.Description}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
